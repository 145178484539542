import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/common/services/data.service';
import { ContactDialogComponent } from './contact-dialog/contact-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  language: string;
  langSub: Subscription;

  constructor(public dialog: MatDialog, public dataService: DataService) {
      this.langSub = this.dataService.currentLang.subscribe(r => this.language = r);
  }

  ngOnInit(): void {

  }
  ngOnDestroy() {
      this.langSub.unsubscribe();
  }

  openContactDialog() {
      this.dialog.open(ContactDialogComponent, {
          data: {
              language: this.language
          }
      });
  }

  changeLang(lang: string) {
      this.dataService.changeLang(lang);
  }

}
