<ng-container *ngIf="this.content && this.language">

  <div class="section footer" fxLayout="column" fxLayoutAlign="center stretch">
    <div class="row-title" fxLayout="row" fxLayoutAlign="space-evenly stretch" fxFlexFill fxLayout.xs="column">
      <div fxLayout="column" fxLayoutAlign="start center" fxFlex="50">
        <div>
          <h1>{{this.content.FOOTER.ABOUT[language]}}</h1>
        </div>
        <div>
          <p>
            {{this.content.FOOTER.ABOUT.DESCRIPTION_1[language]}}<span class="blue">Empower Lab</span>
            {{this.content.FOOTER.ABOUT.DESCRIPTION_2[language]}}
          </p>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start center" fxFlex="50">
        <div>
          <h1>{{this.content.FOOTER.PARTNERS[language]}}</h1>
        </div>
        <div class="img-community slm" onclick="window.location = 'https://slm-sa.com/'"></div>
        <div class="img-community openclass" onclick="window.location = 'https://openclassrooms.com/fr/'"></div>
      </div>
    </div>
    <div class="footer-rights" fxLayout="column" fxLayoutAlign="space-between center">
      <a href="https://www.linkedin.com/company/enkun">
        <mat-icon svgIcon="images/linkedin"></mat-icon>
      </a>
      <a href="mailto:contact@enkun.io">
        contact@enkun.io
      </a>
      <p>
        © {{this.content.FOOTER.RIGHTS[language]}} ICMAAE
      </p>
    </div>
  </div>
</ng-container>
