import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { Contact } from 'src/app/common/models/contact.model';
import { ContactService } from 'src/app/common/services/contact.service';
import { LocalAssetsService } from 'src/app/common/services/local-assets.service';

@Component({
    selector: 'app-contact-dialog',
    templateUrl: './contact-dialog.component.html',
    styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent implements OnInit {
    form: FormGroup;
    content: any;
    language: string = 'fr';

    contactSub: Subscription;

    @ViewChild('btnClose')
    btnClose;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private localJsonService: LocalAssetsService,
        private formBuilder: FormBuilder,
        private contactService: ContactService,
        private snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<ContactDialogComponent>,

    ) {
        localJsonService.getLocalJson('contact.json').subscribe(r =>
            this.content = r
        )
        this.language = data.language;


        this.form = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
            message: ['', Validators.required],
            name: ['', Validators.required],

        });
    }

    ngOnInit(): void {
    }
    ngOnDestroy(): void {
        if (this.contactSub)
            this.contactSub.unsubscribe();
    }
    openSnackBar(message: string, duration: number) {
        this.snackBar.open(message, 'OK', {
            duration: duration
        });
    }
    get userEmail() {
        return this.form.get('email');
    }
    get userMessage() {
        return this.form.get('message');
    }
    get userName() {
        return this.form.get('name');
    }

    submit() {
        if (this.form.valid) {
            const c: Contact = {
                message: this.form.get('message').value,
                username: this.form.get('name').value,
                email: this.form.get('email').value,
            }
            this.contactSub = this.contactService.sendForm(c)
                .subscribe(
                    res => {
                        console.log('HTTP response', res);
                        this.openSnackBar(this.content.CONTACT.SUCCESS[this.language] + ' 🙏', 6000);
                        this.dialogRef.close();
                    },
                    err => {
                        console.log('HTTP Error', err);
                        this.openSnackBar(this.content.CONTACT.ERROR[this.language] + ' 🙏', -1);
                    },
                    () => console.log('HTTP request completed.')
                )
        }
    }

}
