import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TilesSectionComponent } from './tiles-section.component';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [TilesSectionComponent],
  imports: [
    CommonModule,
    FlexLayoutModule
  ],
  exports: [TilesSectionComponent]
})
export class TilesSectionModule { }
