import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-welcome-section',
  templateUrl: './welcome-section.component.html',
  styleUrls: ['./welcome-section.component.scss']
})
export class WelcomeSectionComponent implements OnInit {

  @Input()
  firstTitle: string;

  @Input()
  secondTitle: string;

  @Input()
  thirdTitle: string;

  @Input()
  paragraph: string;

  @Input()
  imgUrl: string;

  @Input()
  imgUrlMobile: string;
  constructor() { }

  ngOnInit(): void {
  }
  getImgStyles(): any {
    const img = "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url('" + this.imgUrl + "')";
    let styles = {
      'background-image': img,
      'background-repeat': 'no-repeat',
      'background-size': 'cover',
      'background-position': 'center'
    };
    return styles;
  }
}
