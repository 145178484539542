import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { WelcomeSectionModule } from './common/components/welcome-section/welcome-section.module';
import { TilesSectionModule } from './common/components/tiles-section/tiles-section.module';
import { HeaderModule } from './pages/header/header.module';
import { FooterModule } from './pages/footer/footer.module';
import { AppUtils } from './common/core/app-utils.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    WelcomeSectionModule,
    TilesSectionModule,
    HeaderModule,
    FooterModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private appUtils: AppUtils
  ) {
    this.appUtils.addSvgIcons([
      'logos/enkun',
      'images/linkedin'
    ]);
  }
}
