<mat-toolbar>
  <div routerLink="/" class="enkun-logo">
    <img src="assets/logos/enkun.svg">
  </div>
  <span fxHide.xs routerLink="/" class="title">EnkUn</span>
  <span class="spacer"></span>

  <div class="container-buttons">

    <button class="menu-join" mat-button [matMenuTriggerFor]="navMenu">
      <span fxHide.xs>{{this.language && this.language == 'fr' ? 'Rejoignez EnkUn !' : 'Embark with us !'}}</span>
      <span fxHide.lg fxHide.sm fxHide.md fxHide.xl>{{this.language && this.language == 'fr' ? 'Joindre' : 'Join'}}</span>
      <!-- <mat-icon>expand_more</mat-icon> -->
    </button>


    <mat-menu #navMenu="matMenu">
      <button routerLink="/associations" mat-menu-item>Associations</button>
      <button routerLink="/mentors" mat-menu-item>Mentors</button>
      <button routerLink="/refugees" mat-menu-item>{{this.language && this.language == 'fr' ? 'Réfugié·es' :
        'Refugees'}}</button>
    </mat-menu>

    <button (click)="openContactDialog()" color="primary" mat-flat-button class="example-icon favorite-icon">
      Contact
    </button>

    <button mat-button [matMenuTriggerFor]="langMenu">
      <span>{{this.language | uppercase }}</span>
      <mat-icon fxHide.xs>expand_more</mat-icon>
    </button>
    <mat-menu #langMenu="matMenu">
      <button (click)="this.changeLang('fr')" mat-menu-item>FR</button>
      <button (click)="this.changeLang('en')" mat-menu-item>EN</button>
    </mat-menu>
  </div>

</mat-toolbar>