<div class="section community" fxLayout="column" fxLayoutAlign="center center" fxHide.lg fxHide.sm fxHide.md fxHide.xl>
  <div class="title" fxLayout="column" fxLayoutAlign="center center">
    <h2>{{this.firstTitle}}<span class="blue">{{this.emphasis}}</span>{{this.secondTitle}}</h2>
    <h3>{{this.subtitle}}</h3>
  </div>
  <div class="row-title" fxLayout="column" fxLayoutAlign="space-evenly stretch" fxFlexFill>
    <div fxLayout="column" fxLayoutAlign="start center" fxFlex="33">
      <div class="img-community" [ngStyle]="this.getImgStyles(0)"></div>
      <div>
        <h1>{{this.contentTitles[0]}}</h1>
      </div>
      <div>
        <p>{{this.contentDesc[0]}}</p>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start center" fxFlex="33">
      <div class="img-community" [ngStyle]="this.getImgStyles(1)"></div>
      <div>
        <h1>{{this.contentTitles[1]}}</h1>
      </div>
      <div>
        <p>{{this.contentDesc[1]}}</p>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start center" fxFlex="33">
      <div class="img-community" [ngStyle]="this.getImgStyles(2)"></div>
      <div>
        <h1>{{this.contentTitles[2]}}</h1>
      </div>
      <div>
        <p>{{this.contentDesc[2]}}</p>
      </div>
    </div>
  </div>
</div>

<div class="section community" fxLayout="column" fxLayoutAlign="center stretch" fxHide.xs>
  <div class="title" fxLayout="column" fxLayoutAlign="center center">
    <h2>{{this.firstTitle}}<span class="blue">{{this.emphasis}}</span>{{this.secondTitle}}</h2>
    <h3>{{this.subtitle}}</h3>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-evenly stretch" fxLayout.xs="column">
    <div fxFlex="33" class="img-community" [ngStyle]="this.getImgStyles(0)"></div>
    <div fxFlex="33" class="img-community" [ngStyle]="this.getImgStyles(1)"></div>
    <div fxFlex="33" class="img-community" [ngStyle]="this.getImgStyles(2)"></div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-evenly stretch" fxLayout.xs="column">
    <div *ngFor="let title of this.contentTitles" fxFlex="33">
      <h1>{{title}}</h1>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-evenly stretch" fxLayout.xs="column">
    <div *ngFor="let desc of this.contentDesc" fxFlex="33">
      <p>{{desc}}</p>
    </div>
  </div>
</div>