import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Contact } from '../models/contact.model';

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    private API_URL: string = environment.API_URL + "contact"
    constructor(private http: HttpClient) { }

    sendForm(contact: Contact): Observable<any> {
        return this.http.post<any>(this.API_URL, contact);
    }
}
