<div class="section welcome" fxLayout="column" fxLayoutAlign="center stretch" [ngStyle]="this.getImgStyles()">
  <div fxLayout="row" fxLayoutAlign="center stretch" fxFlexFill fxLayout.xs="column">
    <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="50">
      <div>
        <h1 [innerHtml]="this.firstTitle"></h1>
      </div>
      <div>
        <h1 [innerHtml]="this.secondTitle"></h1>
      </div>
      <div>
        <h1 [innerHtml]="this.thirdTitle"></h1>
      </div>
      <div>
        <h3><span>EnkUn</span>{{this.paragraph}}
        </h3>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex="50">
      <div class="img-refugees" fxFlexFill>
      </div>
    </div>
  </div>
</div>