import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LocalAssetsService {


    constructor(private http: HttpClient) { }

    getLocalJson(path: string): Observable<any> {
        const localPath: string = "/assets/content/";
        return this.http.get<any>(localPath + path).pipe(
            catchError((err) => {
                console.error('Could not find file:', path);
                return of({});
            })
        );
    }
}
