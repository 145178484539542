import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataService } from 'src/app/common/services/data.service';
import { LocalAssetsService } from 'src/app/common/services/local-assets.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  language: string;
  langSub: Subscription;
  content: any;
  constructor(public dataService: DataService, public localJsonService: LocalAssetsService) {
    this.langSub = this.dataService.currentLang.subscribe(r => this.language = r);
    this.localJsonService.getLocalJson('landing.json').subscribe(r => this.content = r);
  }

  ngOnInit(): void {

  }
  ngOnDestroy() {
    this.langSub.unsubscribe();
  }

}
