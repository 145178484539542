<ng-container *ngIf="this.content; let contact">
    <form [formGroup]="this.form" (ngSubmit)="submit()">
        <h1 mat-dialog-title>{{contact.TITLE[this.language]}}</h1>
        <mat-dialog-content>
            <div fxLayout="column" fxLayoutAlign="center center">
                <mat-form-field appearance="outline">
                    <mat-label>{{contact.FORM_EMAIL[this.language]}}</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix>email</mat-icon>
                </mat-form-field>
                <!-- <div *ngIf="userEmail.errors?.email">
                    Primary Email not valid.
               </div>  -->
                <mat-form-field appearance="outline">
                    <mat-label>{{contact.FORM_NAME[this.language]}}</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon matSuffix>face</mat-icon>
                </mat-form-field>
                <!-- <div *ngIf="userName.errors?.name">
                    Primary Email not valid.
               </div>  -->
                <mat-form-field appearance="outline">
                    <mat-label>Message</mat-label>
                    <mat-icon matSuffix>message</mat-icon>
                    <textarea formControlName="message" matInput #message maxlength="256" cdkTextareaAutosize
                    cdkAutosizeMinRows="3"></textarea>
                    <!-- <mat-hint align="start"><strong>Don't disclose personal info</strong> </mat-hint> -->
                    <!-- <mat-hint align="end">{{message.value.length}} / 256</mat-hint> -->
                  </mat-form-field>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>{{contact.BUTTON_CLOSE[this.language]}}</button>
            <button color="primary" mat-button type="submit">{{contact.BUTTON_SEND[this.language]}}</button>

        </mat-dialog-actions>

    </form>
</ng-container>