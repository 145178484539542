import { Injectable, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

export interface SvgIcon {
    name: string;
    url: string;
}

@Injectable({
    providedIn: 'root'
})
export class AppUtils {
    constructor(
        private ngZone: NgZone,
        private domSanitizer: DomSanitizer,
        private matIconRegistry: MatIconRegistry
    ) { }

    // ---- PUBLIC API(s) ----

    addSvgIcons(icons: string[] | SvgIcon[]) {
        (icons as any[]).forEach((icon: string | SvgIcon) => {
            let svgIcon = icon as SvgIcon;
            if (typeof icon === 'string') {
                svgIcon = { name: icon, url: `assets/${icon}.svg` };
            }
            this.matIconRegistry.addSvgIcon(
                svgIcon.name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(svgIcon.url)
            );
        });
    }

    runOutsideAngular(callback: () => void, animation: boolean = false) {
        if (typeof requestAnimationFrame !== 'undefined') {
            this.ngZone.runOutsideAngular(() => {
                if (animation) {
                    requestAnimationFrame(() => callback());
                } else {
                    callback();
                }
            });
        } else {
            callback();
        }
    }
}
