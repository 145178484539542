import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tiles-section',
  templateUrl: './tiles-section.component.html',
  styleUrls: ['./tiles-section.component.scss']
})
export class TilesSectionComponent implements OnInit {

  @Input()
  firstTitle: string;
  @Input()
  secondTitle: string;

  @Input()
  subtitle: string;

  @Input()
  emphasis: string;

  @Input()
  contentTitles: string[] = [];

  @Input()
  contentDesc: string[] = [];

  @Input()
  contentImages: string[] = [];

  constructor() {
  }

  ngOnInit(): void {

  }
  getImgStyles(index: number): any {
    const img = "url('" + this.contentImages[index] + "')";
    let styles = {
      'background-image': img,
      'background-repeat': 'no-repeat',
      'background-size': 'container',
      'background-position': 'center'
    };
    return styles;
  }

}
