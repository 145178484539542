import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    private langSource = new BehaviorSubject('fr');
    currentLang: Observable<string> = this.langSource.asObservable();


    constructor() { }

    changeLang(lang: string): void {
        this.langSource.next(lang);
    }

}
